export const Block = () => import('../../components/block/index.vue' /* webpackChunkName: "components/block" */).then(c => wrapFunctional(c.default || c))
export const BlocksWrapper = () => import('../../components/blocks-wrapper/index.vue' /* webpackChunkName: "components/blocks-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Btn = () => import('../../components/btn/index.vue' /* webpackChunkName: "components/btn" */).then(c => wrapFunctional(c.default || c))
export const Collapse = () => import('../../components/collapse/index.vue' /* webpackChunkName: "components/collapse" */).then(c => wrapFunctional(c.default || c))
export const FigurePic = () => import('../../components/figure-pic/index.vue' /* webpackChunkName: "components/figure-pic" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/loader/index.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/modal/index.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/page/index.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/tabs/index.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const Pic = () => import('../../components/pic/index.vue' /* webpackChunkName: "components/pic" */).then(c => wrapFunctional(c.default || c))
export const Toolbar = () => import('../../components/toolbar/index.vue' /* webpackChunkName: "components/toolbar" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/app/footer/index.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/app/header/index.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const BlockDesigner = () => import('../../components/block/designer/index.vue' /* webpackChunkName: "components/block-designer" */).then(c => wrapFunctional(c.default || c))
export const BlockGrid = () => import('../../components/block/grid/index.vue' /* webpackChunkName: "components/block-grid" */).then(c => wrapFunctional(c.default || c))
export const BlockRecirculation = () => import('../../components/block/recirculation/index.vue' /* webpackChunkName: "components/block-recirculation" */).then(c => wrapFunctional(c.default || c))
export const BannerMedia = () => import('../../components/banner/media/index.vue' /* webpackChunkName: "components/banner-media" */).then(c => wrapFunctional(c.default || c))
export const BtnLang = () => import('../../components/btn/lang/index.vue' /* webpackChunkName: "components/btn-lang" */).then(c => wrapFunctional(c.default || c))
export const BtnLoadMore = () => import('../../components/btn/load-more/index.vue' /* webpackChunkName: "components/btn-load-more" */).then(c => wrapFunctional(c.default || c))
export const BtnPrimary = () => import('../../components/btn/primary/index.vue' /* webpackChunkName: "components/btn-primary" */).then(c => wrapFunctional(c.default || c))
export const BtnSecondary = () => import('../../components/btn/secondary/index.vue' /* webpackChunkName: "components/btn-secondary" */).then(c => wrapFunctional(c.default || c))
export const BtnShare = () => import('../../components/btn/share/index.vue' /* webpackChunkName: "components/btn-share" */).then(c => wrapFunctional(c.default || c))
export const BtnTertiary = () => import('../../components/btn/tertiary/index.vue' /* webpackChunkName: "components/btn-tertiary" */).then(c => wrapFunctional(c.default || c))
export const CardCatalogue = () => import('../../components/card/catalogue/index.vue' /* webpackChunkName: "components/card-catalogue" */).then(c => wrapFunctional(c.default || c))
export const CardDiary = () => import('../../components/card/diary/index.vue' /* webpackChunkName: "components/card-diary" */).then(c => wrapFunctional(c.default || c))
export const CardLauncher = () => import('../../components/card/launcher/index.vue' /* webpackChunkName: "components/card-launcher" */).then(c => wrapFunctional(c.default || c))
export const CardPress = () => import('../../components/card/press/index.vue' /* webpackChunkName: "components/card-press" */).then(c => wrapFunctional(c.default || c))
export const CardProduct = () => import('../../components/card/product/index.vue' /* webpackChunkName: "components/card-product" */).then(c => wrapFunctional(c.default || c))
export const CardSample = () => import('../../components/card/sample/index.vue' /* webpackChunkName: "components/card-sample" */).then(c => wrapFunctional(c.default || c))
export const CardSearch = () => import('../../components/card/search/index.vue' /* webpackChunkName: "components/card-search" */).then(c => wrapFunctional(c.default || c))
export const CardService = () => import('../../components/card/service/index.vue' /* webpackChunkName: "components/card-service" */).then(c => wrapFunctional(c.default || c))
export const CardSpecialEdition = () => import('../../components/card/special-edition/index.vue' /* webpackChunkName: "components/card-special-edition" */).then(c => wrapFunctional(c.default || c))
export const CardStoreLocator = () => import('../../components/card/store-locator/index.vue' /* webpackChunkName: "components/card-store-locator" */).then(c => wrapFunctional(c.default || c))
export const CarouselSamples = () => import('../../components/carousel/samples/index.vue' /* webpackChunkName: "components/carousel-samples" */).then(c => wrapFunctional(c.default || c))
export const ChipOrderStatus = () => import('../../components/chip/order-status/index.vue' /* webpackChunkName: "components/chip-order-status" */).then(c => wrapFunctional(c.default || c))
export const CollapseGroup = () => import('../../components/collapse/group/index.vue' /* webpackChunkName: "components/collapse-group" */).then(c => wrapFunctional(c.default || c))
export const FormCampaign = () => import('../../components/form/campaign/index.vue' /* webpackChunkName: "components/form-campaign" */).then(c => wrapFunctional(c.default || c))
export const FormCardProductPresentation = () => import('../../components/form/card-product-presentation/index.vue' /* webpackChunkName: "components/form-card-product-presentation" */).then(c => wrapFunctional(c.default || c))
export const FormChangePassword = () => import('../../components/form/change-password/index.vue' /* webpackChunkName: "components/form-change-password" */).then(c => wrapFunctional(c.default || c))
export const FormContact = () => import('../../components/form/contact/index.vue' /* webpackChunkName: "components/form-contact" */).then(c => wrapFunctional(c.default || c))
export const FormCoupon = () => import('../../components/form/coupon/index.vue' /* webpackChunkName: "components/form-coupon" */).then(c => wrapFunctional(c.default || c))
export const FormErrors = () => import('../../components/form/errors/index.vue' /* webpackChunkName: "components/form-errors" */).then(c => wrapFunctional(c.default || c))
export const FormHubspot = () => import('../../components/form/hubspot/index.vue' /* webpackChunkName: "components/form-hubspot" */).then(c => wrapFunctional(c.default || c))
export const FormNewsletter = () => import('../../components/form/newsletter/index.vue' /* webpackChunkName: "components/form-newsletter" */).then(c => wrapFunctional(c.default || c))
export const FormPaypal = () => import('../../components/form/paypal/index.vue' /* webpackChunkName: "components/form-paypal" */).then(c => wrapFunctional(c.default || c))
export const FormPersonalArea = () => import('../../components/form/personal-area/index.vue' /* webpackChunkName: "components/form-personal-area" */).then(c => wrapFunctional(c.default || c))
export const FormSamplePicker = () => import('../../components/form/sample-picker/index.vue' /* webpackChunkName: "components/form-sample-picker" */).then(c => wrapFunctional(c.default || c))
export const FormShoppingAddress = () => import('../../components/form/shopping-address/index.vue' /* webpackChunkName: "components/form-shopping-address" */).then(c => wrapFunctional(c.default || c))
export const FormSignin = () => import('../../components/form/signin/index.vue' /* webpackChunkName: "components/form-signin" */).then(c => wrapFunctional(c.default || c))
export const FormSignup = () => import('../../components/form/signup/index.vue' /* webpackChunkName: "components/form-signup" */).then(c => wrapFunctional(c.default || c))
export const FormStoreLocator = () => import('../../components/form/store-locator/index.vue' /* webpackChunkName: "components/form-store-locator" */).then(c => wrapFunctional(c.default || c))
export const GridAwards = () => import('../../components/grid/awards/index.vue' /* webpackChunkName: "components/grid-awards" */).then(c => wrapFunctional(c.default || c))
export const GridCatalogues = () => import('../../components/grid/catalogues/index.vue' /* webpackChunkName: "components/grid-catalogues" */).then(c => wrapFunctional(c.default || c))
export const GridCss = () => import('../../components/grid/css/index.vue' /* webpackChunkName: "components/grid-css" */).then(c => wrapFunctional(c.default || c))
export const GridDiary = () => import('../../components/grid/diary/index.vue' /* webpackChunkName: "components/grid-diary" */).then(c => wrapFunctional(c.default || c))
export const GridLauncher = () => import('../../components/grid/launcher/index.vue' /* webpackChunkName: "components/grid-launcher" */).then(c => wrapFunctional(c.default || c))
export const GridMosaic = () => import('../../components/grid/mosaic/index.vue' /* webpackChunkName: "components/grid-mosaic" */).then(c => wrapFunctional(c.default || c))
export const GridPress = () => import('../../components/grid/press/index.vue' /* webpackChunkName: "components/grid-press" */).then(c => wrapFunctional(c.default || c))
export const GridProducts = () => import('../../components/grid/products/index.vue' /* webpackChunkName: "components/grid-products" */).then(c => wrapFunctional(c.default || c))
export const GridServices = () => import('../../components/grid/services/index.vue' /* webpackChunkName: "components/grid-services" */).then(c => wrapFunctional(c.default || c))
export const GridSpecialEditions = () => import('../../components/grid/special-editions/index.vue' /* webpackChunkName: "components/grid-special-editions" */).then(c => wrapFunctional(c.default || c))
export const GridText = () => import('../../components/grid/text/index.vue' /* webpackChunkName: "components/grid-text" */).then(c => wrapFunctional(c.default || c))
export const InputCheckbox = () => import('../../components/input/checkbox/index.vue' /* webpackChunkName: "components/input-checkbox" */).then(c => wrapFunctional(c.default || c))
export const InputCounter = () => import('../../components/input/counter/index.vue' /* webpackChunkName: "components/input-counter" */).then(c => wrapFunctional(c.default || c))
export const InputFile = () => import('../../components/input/file/index.vue' /* webpackChunkName: "components/input-file" */).then(c => wrapFunctional(c.default || c))
export const InputRadio = () => import('../../components/input/radio/index.vue' /* webpackChunkName: "components/input-radio" */).then(c => wrapFunctional(c.default || c))
export const InputSelect = () => import('../../components/input/select/index.vue' /* webpackChunkName: "components/input-select" */).then(c => wrapFunctional(c.default || c))
export const InputText = () => import('../../components/input/text/index.vue' /* webpackChunkName: "components/input-text" */).then(c => wrapFunctional(c.default || c))
export const ModalAccount = () => import('../../components/modal/account/index.vue' /* webpackChunkName: "components/modal-account" */).then(c => wrapFunctional(c.default || c))
export const ModalAddToMoodboard = () => import('../../components/modal/add-to-moodboard/index.vue' /* webpackChunkName: "components/modal-add-to-moodboard" */).then(c => wrapFunctional(c.default || c))
export const ModalAside = () => import('../../components/modal/aside/index.vue' /* webpackChunkName: "components/modal-aside" */).then(c => wrapFunctional(c.default || c))
export const ModalBookAppointment = () => import('../../components/modal/book-appointment/index.vue' /* webpackChunkName: "components/modal-book-appointment" */).then(c => wrapFunctional(c.default || c))
export const ModalDesigner = () => import('../../components/modal/designer/index.vue' /* webpackChunkName: "components/modal-designer" */).then(c => wrapFunctional(c.default || c))
export const ModalFacets = () => import('../../components/modal/facets/index.vue' /* webpackChunkName: "components/modal-facets" */).then(c => wrapFunctional(c.default || c))
export const ModalHandleMoodboard = () => import('../../components/modal/handle-moodboard/index.vue' /* webpackChunkName: "components/modal-handle-moodboard" */).then(c => wrapFunctional(c.default || c))
export const ModalLoader = () => import('../../components/modal/loader/index.vue' /* webpackChunkName: "components/modal-loader" */).then(c => wrapFunctional(c.default || c))
export const ModalMenu = () => import('../../components/modal/menu/index.vue' /* webpackChunkName: "components/modal-menu" */).then(c => wrapFunctional(c.default || c))
export const ModalNewsletter = () => import('../../components/modal/newsletter/index.vue' /* webpackChunkName: "components/modal-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ModalOrderDetails = () => import('../../components/modal/order-details/index.vue' /* webpackChunkName: "components/modal-order-details" */).then(c => wrapFunctional(c.default || c))
export const ModalProductVariantModuleZoom = () => import('../../components/modal/product-variant-module-zoom/index.vue' /* webpackChunkName: "components/modal-product-variant-module-zoom" */).then(c => wrapFunctional(c.default || c))
export const ModalProductVariantSpecs = () => import('../../components/modal/product-variant-specs/index.vue' /* webpackChunkName: "components/modal-product-variant-specs" */).then(c => wrapFunctional(c.default || c))
export const ModalSearch = () => import('../../components/modal/search/index.vue' /* webpackChunkName: "components/modal-search" */).then(c => wrapFunctional(c.default || c))
export const ModalShoppingAddress = () => import('../../components/modal/shopping-address/index.vue' /* webpackChunkName: "components/modal-shopping-address" */).then(c => wrapFunctional(c.default || c))
export const ModalVideo = () => import('../../components/modal/video/index.vue' /* webpackChunkName: "components/modal-video" */).then(c => wrapFunctional(c.default || c))
export const TextAddress = () => import('../../components/text/address/index.vue' /* webpackChunkName: "components/text-address" */).then(c => wrapFunctional(c.default || c))
export const TextHtml = () => import('../../components/text/html/index.vue' /* webpackChunkName: "components/text-html" */).then(c => wrapFunctional(c.default || c))
export const TextPrice = () => import('../../components/text/price/index.vue' /* webpackChunkName: "components/text-price" */).then(c => wrapFunctional(c.default || c))
export const ToolbarGotoCheckout = () => import('../../components/toolbar/goto-checkout/index.vue' /* webpackChunkName: "components/toolbar-goto-checkout" */).then(c => wrapFunctional(c.default || c))
export const ToolbarProduct = () => import('../../components/toolbar/product/index.vue' /* webpackChunkName: "components/toolbar-product" */).then(c => wrapFunctional(c.default || c))
export const ToolbarTabNav = () => import('../../components/toolbar/tab-nav/index.vue' /* webpackChunkName: "components/toolbar-tab-nav" */).then(c => wrapFunctional(c.default || c))
export const VectorCreditCard = () => import('../../components/vector/credit-card/index.vue' /* webpackChunkName: "components/vector-credit-card" */).then(c => wrapFunctional(c.default || c))
export const VectorGlamora = () => import('../../components/vector/glamora/index.vue' /* webpackChunkName: "components/vector-glamora" */).then(c => wrapFunctional(c.default || c))
export const VectorIcon = () => import('../../components/vector/icon/index.vue' /* webpackChunkName: "components/vector-icon" */).then(c => wrapFunctional(c.default || c))
export const VectorProductVariantModules = () => import('../../components/vector/product-variant-modules/index.vue' /* webpackChunkName: "components/vector-product-variant-modules" */).then(c => wrapFunctional(c.default || c))
export const VideoBg = () => import('../../components/video/bg/index.vue' /* webpackChunkName: "components/video-bg" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../components/video/player/index.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const BlockBannerChapter = () => import('../../components/block/banner/chapter/index.vue' /* webpackChunkName: "components/block-banner-chapter" */).then(c => wrapFunctional(c.default || c))
export const BlockBannerHero = () => import('../../components/block/banner/hero/index.vue' /* webpackChunkName: "components/block-banner-hero" */).then(c => wrapFunctional(c.default || c))
export const BlockBannerMedia = () => import('../../components/block/banner/media/index.vue' /* webpackChunkName: "components/block-banner-media" */).then(c => wrapFunctional(c.default || c))
export const BlockCarouselMultiratio = () => import('../../components/block/carousel/multiratio/index.vue' /* webpackChunkName: "components/block-carousel-multiratio" */).then(c => wrapFunctional(c.default || c))
export const BlockCarouselProducts = () => import('../../components/block/carousel/products/index.vue' /* webpackChunkName: "components/block-carousel-products" */).then(c => wrapFunctional(c.default || c))
export const BlockCarouselProjects = () => import('../../components/block/carousel/projects/index.vue' /* webpackChunkName: "components/block-carousel-projects" */).then(c => wrapFunctional(c.default || c))
export const BlockFormCampaign = () => import('../../components/block/form/campaign/index.vue' /* webpackChunkName: "components/block-form-campaign" */).then(c => wrapFunctional(c.default || c))
export const BlockFormHubspot = () => import('../../components/block/form/hubspot/index.vue' /* webpackChunkName: "components/block-form-hubspot" */).then(c => wrapFunctional(c.default || c))
export const BlockLauncherCarousel = () => import('../../components/block/launcher/carousel/index.vue' /* webpackChunkName: "components/block-launcher-carousel" */).then(c => wrapFunctional(c.default || c))
export const BlockLauncherVideo = () => import('../../components/block/launcher/video/index.vue' /* webpackChunkName: "components/block-launcher-video" */).then(c => wrapFunctional(c.default || c))
export const BlockListMoodboards = () => import('../../components/block/list/moodboards/index.vue' /* webpackChunkName: "components/block-list-moodboards" */).then(c => wrapFunctional(c.default || c))
export const BlockListOrders = () => import('../../components/block/list/orders/index.vue' /* webpackChunkName: "components/block-list-orders" */).then(c => wrapFunctional(c.default || c))
export const BlockProductVariant = () => import('../../components/block/product/variant/index.vue' /* webpackChunkName: "components/block-product-variant" */).then(c => wrapFunctional(c.default || c))
export const BlockRecirculationDiary = () => import('../../components/block/recirculation/diary/index.vue' /* webpackChunkName: "components/block-recirculation-diary" */).then(c => wrapFunctional(c.default || c))
export const BlockRecirculationLauncher = () => import('../../components/block/recirculation/launcher/index.vue' /* webpackChunkName: "components/block-recirculation-launcher" */).then(c => wrapFunctional(c.default || c))
export const BlockRecirculationProducts = () => import('../../components/block/recirculation/products/index.vue' /* webpackChunkName: "components/block-recirculation-products" */).then(c => wrapFunctional(c.default || c))
export const BlockTextBlockquote = () => import('../../components/block/text/blockquote/index.vue' /* webpackChunkName: "components/block-text-blockquote" */).then(c => wrapFunctional(c.default || c))
export const BlockTextBody = () => import('../../components/block/text/body/index.vue' /* webpackChunkName: "components/block-text-body" */).then(c => wrapFunctional(c.default || c))
export const BlockTextCta = () => import('../../components/block/text/cta/index.vue' /* webpackChunkName: "components/block-text-cta" */).then(c => wrapFunctional(c.default || c))
export const BlockTextDetails = () => import('../../components/block/text/details/index.vue' /* webpackChunkName: "components/block-text-details" */).then(c => wrapFunctional(c.default || c))
export const BlockTextFaq = () => import('../../components/block/text/faq/index.vue' /* webpackChunkName: "components/block-text-faq" */).then(c => wrapFunctional(c.default || c))
export const BlockTextHeader = () => import('../../components/block/text/header/index.vue' /* webpackChunkName: "components/block-text-header" */).then(c => wrapFunctional(c.default || c))
export const BlockTextHtml = () => import('../../components/block/text/html/index.vue' /* webpackChunkName: "components/block-text-html" */).then(c => wrapFunctional(c.default || c))
export const BlockTextIubenda = () => import('../../components/block/text/iubenda/index.vue' /* webpackChunkName: "components/block-text-iubenda" */).then(c => wrapFunctional(c.default || c))
export const BlockTextQuestions = () => import('../../components/block/text/questions/index.vue' /* webpackChunkName: "components/block-text-questions" */).then(c => wrapFunctional(c.default || c))
export const BlockVideoPlayer = () => import('../../components/block/video/player/index.vue' /* webpackChunkName: "components/block-video-player" */).then(c => wrapFunctional(c.default || c))
export const BtnIconPrimary = () => import('../../components/btn/icon/primary/index.vue' /* webpackChunkName: "components/btn-icon-primary" */).then(c => wrapFunctional(c.default || c))
export const BtnIconSecondary = () => import('../../components/btn/icon/secondary/index.vue' /* webpackChunkName: "components/btn-icon-secondary" */).then(c => wrapFunctional(c.default || c))
export const BtnNavPrimary = () => import('../../components/btn/nav/primary/index.vue' /* webpackChunkName: "components/btn-nav-primary" */).then(c => wrapFunctional(c.default || c))
export const CardProductVariant = () => import('../../components/card/product/variant/index.vue' /* webpackChunkName: "components/card-product-variant" */).then(c => wrapFunctional(c.default || c))
export const FormContactHubspot = () => import('../../components/form/contact/hubspot/index.vue' /* webpackChunkName: "components/form-contact-hubspot" */).then(c => wrapFunctional(c.default || c))
export const InputCheckboxGroup = () => import('../../components/input/checkbox/group/index.vue' /* webpackChunkName: "components/input-checkbox-group" */).then(c => wrapFunctional(c.default || c))
export const ListItemCart = () => import('../../components/list/item/cart/index.vue' /* webpackChunkName: "components/list-item-cart" */).then(c => wrapFunctional(c.default || c))
export const ListItemMoodboard = () => import('../../components/list/item/moodboard/index.vue' /* webpackChunkName: "components/list-item-moodboard" */).then(c => wrapFunctional(c.default || c))
export const ListItemOrder = () => import('../../components/list/item/order/index.vue' /* webpackChunkName: "components/list-item-order" */).then(c => wrapFunctional(c.default || c))
export const ModalSampleCta = () => import('../../components/modal/sample/cta/index.vue' /* webpackChunkName: "components/modal-sample-cta" */).then(c => wrapFunctional(c.default || c))
export const ModalSampleDetails = () => import('../../components/modal/sample/details/index.vue' /* webpackChunkName: "components/modal-sample-details" */).then(c => wrapFunctional(c.default || c))
export const ModalSamplePicker = () => import('../../components/modal/sample/picker/index.vue' /* webpackChunkName: "components/modal-sample-picker" */).then(c => wrapFunctional(c.default || c))
export const PageContactsAccounting = () => import('../../components/page/contacts/accounting/index.vue' /* webpackChunkName: "components/page-contacts-accounting" */).then(c => wrapFunctional(c.default || c))
export const PageContactsBecomeAPartner = () => import('../../components/page/contacts/become-a-partner/index.vue' /* webpackChunkName: "components/page-contacts-become-a-partner" */).then(c => wrapFunctional(c.default || c))
export const PageContactsCareers = () => import('../../components/page/contacts/careers/index.vue' /* webpackChunkName: "components/page-contacts-careers" */).then(c => wrapFunctional(c.default || c))
export const PageContactsCustomerCareService = () => import('../../components/page/contacts/customer-care-service/index.vue' /* webpackChunkName: "components/page-contacts-customer-care-service" */).then(c => wrapFunctional(c.default || c))
export const PageContactsHeadquarters = () => import('../../components/page/contacts/headquarters/index.vue' /* webpackChunkName: "components/page-contacts-headquarters" */).then(c => wrapFunctional(c.default || c))
export const PageContactsPress = () => import('../../components/page/contacts/press/index.vue' /* webpackChunkName: "components/page-contacts-press" */).then(c => wrapFunctional(c.default || c))
export const PageContactsProfessionalConsulting = () => import('../../components/page/contacts/professional-consulting/index.vue' /* webpackChunkName: "components/page-contacts-professional-consulting" */).then(c => wrapFunctional(c.default || c))
export const PageContactsSalesRepresentatives = () => import('../../components/page/contacts/sales-representatives/index.vue' /* webpackChunkName: "components/page-contacts-sales-representatives" */).then(c => wrapFunctional(c.default || c))
export const PageContactsShowrooms = () => import('../../components/page/contacts/showrooms/index.vue' /* webpackChunkName: "components/page-contacts-showrooms" */).then(c => wrapFunctional(c.default || c))
export const ToolbarHeaderAccount = () => import('../../components/toolbar/header/account/index.vue' /* webpackChunkName: "components/toolbar-header-account" */).then(c => wrapFunctional(c.default || c))
export const ToolbarHeaderCataloguesBrandTools = () => import('../../components/toolbar/header/catalogues-brand-tools/index.vue' /* webpackChunkName: "components/toolbar-header-catalogues-brand-tools" */).then(c => wrapFunctional(c.default || c))
export const ToolbarHeaderDiary = () => import('../../components/toolbar/header/diary/index.vue' /* webpackChunkName: "components/toolbar-header-diary" */).then(c => wrapFunctional(c.default || c))
export const ToolbarHeaderFacets = () => import('../../components/toolbar/header/facets/index.vue' /* webpackChunkName: "components/toolbar-header-facets" */).then(c => wrapFunctional(c.default || c))
export const ToolbarHeaderPressLounge = () => import('../../components/toolbar/header/press-lounge/index.vue' /* webpackChunkName: "components/toolbar-header-press-lounge" */).then(c => wrapFunctional(c.default || c))
export const ToolbarHeaderSpecialEditionsCollection = () => import('../../components/toolbar/header/special-editions-collection/index.vue' /* webpackChunkName: "components/toolbar-header-special-editions-collection" */).then(c => wrapFunctional(c.default || c))
export const ToolbarHeaderSubmenu = () => import('../../components/toolbar/header/submenu/index.vue' /* webpackChunkName: "components/toolbar-header-submenu" */).then(c => wrapFunctional(c.default || c))
export const BlockTextDetailsMaterial = () => import('../../components/block/text/details/material/index.vue' /* webpackChunkName: "components/block-text-details-material" */).then(c => wrapFunctional(c.default || c))
export const BtnNavIconSecondary = () => import('../../components/btn/nav/icon/secondary/index.vue' /* webpackChunkName: "components/btn-nav-icon-secondary" */).then(c => wrapFunctional(c.default || c))
export const CardProductVariantModule = () => import('../../components/card/product/variant/module/index.vue' /* webpackChunkName: "components/card-product-variant-module" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
